<template>
    <TextInput v-bind="$attrs" :type="showPassword ? 'text' : 'password'">
        <template #left> <PassIcon class="size-6" /> </template>
        <template #right>
            <EyeIcon v-if="!showPassword" class="size-4" @click="togglePasswordVisibility" />
            <EyeSlashIcon v-else class="size-4" @click="togglePasswordVisibility" />
        </template>
    </TextInput>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import {TextInput} from '@/Components/Frontend/Form/';
import {PassIcon} from '@/images/icons';
import {EyeIcon, EyeSlashIcon} from '@heroicons/vue/24/outline';

defineOptions({
    inheritAttrs: false,
});

const showPassword = ref(false);

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};
</script>
